import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => ({
  isOpen: state.auth.flows.username.modalIsOpen,
  value: state.auth.flows.username.value,
  fetching: state.auth.flows.username.fetching,
})

const mapDispatch = (dispatch: any) => ({
  onSubmit: () => {
    dispatch(actions.auth.flows.username.$submit())
  },
  onClose: () => {
    dispatch(actions.auth.flows.username.setModalOpen({ isOpen: false }))
  },
  onChange: (username: string) => {
    dispatch(actions.auth.flows.username.change({ value: username }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
