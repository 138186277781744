import React from "react"
import { connector, ContainerProps } from "./containers/ProfileStats.container"
import { AvatarUsernameAndLevel } from "./components/AvatarUsernameAndLevel"
import { ModalSelectAvatar } from "./components/ModalSelectAvatar"
import { RenameUsernameModal } from "./components/RenameUsernameModal"
import { PageHeading } from "../PageHeading/PageHeading"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

export type Props = {
  authenticated: boolean
  subscribed: boolean
  onUnsubscribe: () => void
}

export const Wrapper: React.FC<Props> = (props) => {
  if (!props.authenticated) return <></>

  return (
    <>
      <PageHeading
        title={<FormattedMessage id="profile/title" />}
        description={<FormattedMessage id="profile/description" />}
      />

      <div className="relative px-2">
        <div className="max-w-3xl mx-auto mt-4">
          <AvatarUsernameAndLevel />

          {props.subscribed && (
            <div className="pt-8 border-t border-slate-200 mt-8">
              <div className="font-display text-2xl font-semibold">
                <FormattedMessage id="pricing/profile/title" />
              </div>
              <p>
                <FormattedMessage id="pricing/profile/description" />
              </p>
              <button
                onClick={props.onUnsubscribe}
                className="flex mx-auto mt-12 font-semibold font-display hover:bg-red-600 transition-all ease-in-out cursor-pointer items-center justify-center py-3 px-4 text-white bg-red-500 border border-red-600 rounded shadow-btn shadow-red-600"
              >
                <FormattedMessage id="pricing/unsubscribe" />
              </button>
            </div>
          )}
        </div>
      </div>

      <ModalSelectAvatar />
      <RenameUsernameModal />
    </>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const ProfileStats = connector(Container)
