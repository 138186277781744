import React from "react"
import { UserEntity } from "../../../entities/UserEntity"
import { LevelBar } from "../../LevelBar/LevelBar"
import {
  connector,
  ContainerProps,
} from "./containers/AvatarUsernameAndLevel.containers"
import {
  ArrowLeftOnRectangleIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid"
import { navigate } from "gatsby"
import { navigateToHome } from "../../../i18n/utils/getUrl"
import { useIntl } from "react-intl"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

export const Wrapper: React.FC<{
  level: number
  points: number
  nextLevelPoints: number
  user: UserEntity | null
  onOpenAvatar: () => void
  onLogout: () => void
  onOpenUsernameModal: () => void
}> = (props) => {
  const intl = useIntl()

  return (
    <div>
      <div className="flex flex-col items-center sm:flex-row">
        <div className="flex items-center justify-center flex-shrink-0">
          <div onClick={props.onOpenAvatar} className="relative inline-block">
            <img
              src={`/avatars/${props.user?.avatar}`}
              className="block w-28 h-28 mx-auto rounded-full sm:ml-0"
            />

            <div className="absolute top-0 p-2 border-2 hover:bg-blue-600 transition-all ease-in-out cursor-pointer border-white right-0 rounded-full bg-blue-500 text-white">
              <PencilSquareIcon className="w-4 h-4" />
            </div>
          </div>
        </div>

        <div className="relative w-full text-center sm:pl-10 sm:text-left">
          <div className="flex flex-col sm:flex-row">
            <div>
              <div className="relative flex items-end justify-center sm:justify-start">
                <div className="text-2xl font-display font-medium text-slate-900">
                  {props.user?.username}
                </div>
              </div>

              {props.user?.email && (
                <div className="text-slate-500 font-display text-xl">
                  {props.user?.email}
                </div>
              )}
            </div>

            <div className="md:ml-auto mt-2 md:mt-0">
              <div
                onClick={props.onOpenUsernameModal}
                className="flex font-display font-semibold  hover:bg-slate-100 transition-all ease-in-out cursor-pointer items-center justify-center py-3 px-4 bg-white rounded border shadow-btn shadow-slate-200 border-slate-200"
              >
                <PencilSquareIcon className="w-5 h-5 mr-2" />
                <FormattedMessage id="profile/change-username/button" />
              </div>
              <button
                onClick={() => {
                  props.onLogout()
                  navigate(navigateToHome(intl.locale))
                }}
                className="flex mx-auto w-full mt-4 font-semibold font-display hover:bg-slate-600 transition-all ease-in-out cursor-pointer items-center justify-center py-3 px-4 text-white bg-slate-500 border border-slate-600 rounded shadow-btn shadow-slate-600"
              >
                <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-2" />
                <FormattedMessage id="drawer.logout" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const AvatarUsernameAndLevel = connector(Container)
