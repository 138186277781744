import { connect, ConnectedProps } from "react-redux"
import { marketplaceAvatarItems } from "../../../../database/marketplace-items"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => ({
  isOpen: state.auth.flows.avatar.modalIsOpen,
  selected: state.auth.flows.avatar.selected,
  fetching: state.auth.flows.avatar.fetching,
  achievements: state.achievements.achievements,
  marketplaceAvatars: marketplaceAvatarItems.filter((avatar) => {
    return state.marketplace.items.has(avatar.itemId)
  })
})

const mapDispatch = (dispatch: any) => ({
  onSubmit: () => {
    dispatch(actions.auth.flows.avatar.$submit())
  },
  onClose: () => {
    dispatch(actions.auth.flows.avatar.setModalOpen({ isOpen: false }))
  },
  onSelect: (avatar: string) => {
    dispatch(actions.auth.flows.avatar.select({ value: avatar }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
