import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  connector,
  ContainerProps,
} from "./containers/RenameUsernameModal.containers"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

type Props = {
  isOpen: boolean
  value: string | null
  fetching: boolean
  onChange: (username: string) => void
  onClose: () => void
  onSubmit: () => void
}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10" onClose={() => false}>
        <div className="flex items-end justify-center h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-slate-900 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex items-center justify-center w-full h-full py-8">
              <div className="inline-flex flex-col w-full overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:align-middle sm:max-w-sm">
                <div className="relative z-50 p-4 bg-white">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-slate-900"
                  >
                    <FormattedMessage id="username/modal/title" />
                  </Dialog.Title>
                </div>

                <div className="w-full px-2">
                  <input
                    className={`focus:ring-0 text-lg font-light appearance-none px-4 relative rounded block w-full py-3 border placeholder-slate-400 border-slate-200 text-slate-900 outline-none focus:outline-none focus:border-blue-300`}
                    value={props.value || ""}
                    name="name"
                    type="name"
                    autoComplete="off"
                    placeholder={"sudoku-master"}
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return
                      props.onSubmit()
                    }}
                    onChange={({ target }) => props.onChange(target.value)}
                  />
                </div>

                <div>
                  <div className="z-50 grid flex-shrink-0 grid-cols-2 gap-2 p-2 ">
                    <div
                      className="inline-flex items-center justify-center w-full text-sm font-semibold text-slate-500 uppercase transition-all duration-300 ease-in-out rounded-md shadow-sm cursor-pointer hover:bg-slate-200 hover:text-slate-900 focus:outline-none"
                      onClick={() => props.onClose()}
                    >
                      <FormattedMessage id="username/modal/cancel" />
                    </div>

                    <div className=" group">
                      <div
                        data-testid="authentication/next"
                        onClick={props.onSubmit}
                        className={`transform w-full relative z-10 group flex cursor-pointer items-center uppercase h-11 bg-blue-500 text-white transition-all duration-300 ease-in-out group-hover:bg-blue-600 rounded text-sm font-semibold justify-center`}
                      >
                        {!props.fetching && (
                          <>
                            <FormattedMessage id="username/modal/submit" />
                          </>
                        )}

                        {props.fetching && (
                          <>
                            <div className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"></div>
                            <div
                              className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                              style={{ animationDelay: "50ms" }}
                            ></div>
                            <div
                              className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                              style={{ animationDelay: "100ms" }}
                            ></div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const RenameUsernameModal = connector(Container)
