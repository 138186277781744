import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { getListOfAvailableAvatars } from "../../../utils/getRandomAvatar"
import {
  connector,
  ContainerProps,
} from "./containers/ModalSelectAvatar.containers"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"
import { AchievementWithRewardEntity } from "../../../entities/AchievementEntity"
import { MarketplaceEntity } from "../../../entities/MarketplaceEntity"

type Props = {
  isOpen: boolean
  selected: string | null
  fetching: boolean
  marketplaceAvatars: MarketplaceEntity[]
  achievements: AchievementWithRewardEntity[]
  onSelect: (avatar: string) => void
  onClose: () => void
  onSubmit: () => void
}

const Avatar: React.FC<{
  id: string
  onSelect: (avatar: string) => void
  selected: boolean
}> = (props) => (
  <div
    key={props.id}
    onClick={() => props.onSelect(props.id)}
    className={`relative w-full transition-all rounded ring-4 ring-transparent border-transparent duration-300 ease-in-out transform shadow cursor-pointer hover:shadow-xl hover:scale-110 hover:z-20 ${
      props.selected ? "scale-110 z-30 shadow-xl ring-4 ring-blue-500" : ""
    }`}
  >
    <img className="w-full h-auto rounded" src={`/avatars/${props.id}`} />
  </div>
)

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10" onClose={() => false}>
        <div className="flex items-end justify-center h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-slate-900 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="h-full py-8">
              <div className="inline-flex flex-col h-full overflow-auto text-left align-bottom transition-all transform bg-slate-100 shadow-xl rounded-xl sm:align-middle sm:max-w-sm sm:w-full">
                <div className="relative z-50 p-4 bg-white shadow-2xl rounded-xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-slate-900"
                  >
                    <FormattedMessage id="avatar/modal/title" />
                  </Dialog.Title>
                </div>

                <div className="grid grid-cols-4 gap-2 p-2 overflow-y-auto">
                  {props.marketplaceAvatars.map(({ itemId }) => {
                    return (
                      <Avatar
                        key={itemId}
                        id={itemId}
                        onSelect={props.onSelect}
                        selected={props.selected === itemId}
                      />
                    )
                  })}

                  {getListOfAvailableAvatars(props.achievements).map((id) => {
                    return (
                      <Avatar
                        key={id}
                        id={id}
                        onSelect={props.onSelect}
                        selected={props.selected === id}
                      />
                    )
                  })}
                </div>

                <div className="z-50 grid flex-shrink-0 grid-cols-2 gap-2 p-2 bg-white shadow-2xl rounded-xl">
                  <div
                    className="inline-flex items-center justify-center w-full text-sm font-semibold text-slate-500 uppercase transition-all duration-300 ease-in-out rounded-md shadow-sm cursor-pointer hover:bg-slate-200 hover:text-slate-900 focus:outline-none"
                    onClick={() => props.onClose()}
                  >
                    <FormattedMessage id="avatar/modal/cancel" />
                  </div>
                  <div className="group">
                    <div
                      data-testid="authentication/next"
                      onClick={props.onSubmit}
                      className={`transform w-full relative z-10 group flex cursor-pointer items-center uppercase h-11 bg-blue-500 text-white transition-all duration-300 ease-in-out group-hover:bg-blue-600 rounded text-sm font-semibold justify-center`}
                    >
                      {!props.fetching && (
                        <>
                          <FormattedMessage id="avatar/modal/submit" />
                        </>
                      )}

                      {props.fetching && (
                        <>
                          <div className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"></div>
                          <div
                            className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                            style={{ animationDelay: "50ms" }}
                          ></div>
                          <div
                            className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                            style={{ animationDelay: "100ms" }}
                          ></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const ModalSelectAvatar = connector(Container)
